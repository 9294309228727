<template>
  <div class="ball_comp">
    <g-loading :loading="loading" />
    <!-- 声音开关 -->
    <div class="volume_bg" @click="volume_toggle" @touchstart.stop>
      <img src="../assets/img/volume_open.png" v-if="volume_status" />
      <img src="../assets/img/volume_close.png" v-else />
    </div>

    <canvas class="jsmpeg_canvas" ref="video_el"></canvas>
  </div>
</template>

<script>

export default {
  props: {
    video_name: {
      default: 'B',
      type: String,
    }
  },
  data () {
    return {
      loading: false,
      player: null,
      volume_status: false,
      volume_unlock: true,
    }
  },
  components: {
  },
  mounted() {
    this.loading = true
    const video_el = this.$refs.video_el
    this.player = new JSMpeg.Player(`./assets/video/${this.video_name}.ts`, {
      canvas: video_el,
      decodeFirstFrame: true,
      // poster: './assets/d_1.png',
      throttled: false,
      progressive: true,
      audio: true,
      chunkSize: 1 * 1024 * 1024,
      onSourceEstablished: () => {
        if (!video_el) return
        video_el.style.opacity = '1'
        this.player.play()
        this.$emit('play')
        this.loading = false
        this.player.volume = 0
      },
    })

    document.addEventListener('touchstart', this.touchstart, false)
  },
  beforeDestroy() {
    this.player?.destroy()
  },
  methods: {
    unlocked () {
      this.volume_unlock = false
      this.volume_open()
      document.removeEventListener('touchstart', this.touchstart, false)
    },
    touchstart () {
      this.player.audioOut.unlock(this.unlocked)
      document.removeEventListener('touchstart', this.touchstart, false)
    },
    volume_open () {
      this.player.volume = 1
      this.volume_status = true
    },
    volume_close () {
      this.player.volume = 0
      this.volume_status = false
    },
    volume_toggle () {
      if (!this.volume_status) {
        if (this.volume_unlock)  {
          this.touchstart()
        } else {
          this.volume_open()
        }
      } else {
        this.volume_close()
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ball_comp {
  width: 100%;
  height: 100%;
}

.jsmpeg_canvas {
  width: 100%;
  height: 100%;
  opacity: 0;
}
.volume_bg {
  width: 40px;
  height: 40px;
  background: rgba(0,0,0,0.3);
  border-radius: 50%;
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    width: 20px;
    height: 20px;
  }
}
</style>
