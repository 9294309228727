<template>
  <div class="main_content">
    <div class="yanshi">演示版</div>
    <DigitalHuman
      ref="digital_human_el"
      :video_name="$route.query.video_name"
      />
    <!-- 文稿 -->
    <div class="ai_text" @click="to_url">
      <div><div class="s1">AI</div><div class="s2">文稿</div></div>
    </div>
    <!-- 与我互动 -->
    <div class="to_bg" @click="to_url2">
      与我<br/>互动
      <img src="../../assets/img/digital-human/hand.png" class="img">
    </div>
  </div>
</template>

<script>
import DigitalHuman from '../../components/DigitalHuman'
import { get_url_params } from '../../utils/format'

export default {
  data() {
    return {
      digital_human_el: null,
    }
  },
  computed: {

  },
  components: {
    DigitalHuman
  },
  created () {

  },
  mounted () {
    this.digital_human_el = this.$refs.digital_human_el
  },
  methods: {
    to_url () {
      const redirect = decodeURIComponent(this.$route.query.redirect)
      this.$router.push(redirect)
    },
    to_url2 () {
      const redirect = decodeURIComponent(this.$route.query.redirect)
      const query = get_url_params(redirect)
      if (query.msg_id) {
        location.href = GLOBAL_CONFIG.digital_human_url + '/login?msg_id=' + query.msg_id
      } else {
        location.href = GLOBAL_CONFIG.digital_human_url + '/login'
      }

    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.yanshi {
  padding: 4px 7px;
  background: linear-gradient(90deg, #80F9F0 0%, #1D4EFF 100%);
  opacity: 0.6;
  border-radius: 0px 0px 0px 7px;
  position: fixed;
  top: 0;
  right: 0px;
  font-size: 12px;
  line-height: 12px;
  color: #FFFFFF;
  z-index: 9999;
}
.main_content {
  width: 100%;
  height: 100%;
  position: relative;
}
.ai_text {
  width: 42px;
  height: 42px;
  background: rgba(0,0,0,0.12);
  border-radius: 20px 0px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 0px;
  text-align: center;
  & .s1 {
    color: transparent;
    background: linear-gradient(330deg, #6200FF 0%, #55FFFF 53%, #FFFFFF 100%);
    /* 背景裁剪，即让文字使用背景色 */
    background-clip: text;
    -webkit-background-clip: text;
    font-weight: bold;
  }
  & .s2 {
    font-size: 12px;
    color: #fff;
  }
}

@keyframes hand {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(20px, 20px);
  }
}

.to_bg {
  width: 49px;
  height: 49px;
  background: rgba(0,0,0,0.6);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #FFFFFF;
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 9999;
  & .img {
    width: 32px;
    height: auto;
    position: absolute;
    top: 24px;
    left: 24px;
    animation: hand 2s ease-out infinite reverse;
  }
}
</style>
